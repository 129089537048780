<template>
  <div>
    <Loading v-if="$global.state.loading" />

    <div class="q-ma-md" v-else>
      <div
        v-if="items.length > 0"
        class="flex-grow overflow-auto"
        style="width: 100%; direction: ltr"
      >
        <flowy
          class="q-mx-auto zoomable"
          :nodes="node"
          :beforeMove="beforeMove"
          @move="move"
          @drag-start="onDragStart"
        ></flowy>
      </div>
      <div v-else class="d-flex justify-center align-center">
        <h2>لم يتم {{ $t("add") }} {{ meta.single }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  components: {},
  computed: {
    meta() {
      return this.$route.meta;
    },
    items() {
      return this.$global.state.organizations;
    },
    node() {
      let nodes = [];
      this.items.forEach((element) => {
        if (element.parentId == null) {
          element.parentId = -1;
        }
        nodes.push({
          id: element.id,
          parentId: element.parentId,
          nodeComponent: "demo-node",
          ...element,
          data: {
            text: element.name,
            title: element.name,
            description: element.organizationType.name,
          },
        });
      });
      return nodes;
    },
  },

  data() {
    return {
      holder: [],
      dragging: false,
      blocks: [
        {
          preview: {
            title: "New visitor",
          },
          node: {
            title: "New visitor",
            description:
              "<span>When a <b>new visitor</b> goes to <b>Site 1</span></span>",
          },
        },
      ],
      nodes: [],
    };
  },
  mounted() {
    this.getItems();
    this.$eventBus.$on(`refresh`, () => {
      console.log("refresh");
      this.getItems();
    });
    this.$global.dispatch(`getOrganizationType`);
    this.$global.dispatch(`getUser`);
  },
  methods: {
    getItems() {
      this.$global.dispatch(`get${this.meta.endPoint}`);
    },

    openAddDialog() {
      console.log("test");
      var item = {
        dialogType: "add",
      };
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
    },

    openEditDialog(item) {
      item.dialogType = "edit";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
      this.$eventBus.$emit("fill-fields");
    },

    beforeMove({ to, from }) {
      if (from && from.parentId === -1) {
        return false;
      }
      // we're adding a new node (not moving an existing one)
      if (from === null) {
        // we've passed this attribute to the v-card
        if (this.newDraggingBlock["custom-attribute"] === false) {
          return false;
        }
      }

      return true;
    },
    remove(event) {
      console.log("remove", event);

      // node we're dragging to
      const { node } = event;

      // we use lodash in this demo to remove node from the array
      const nodeIndex = _.findIndex(this.nodes, {
        id: node.id,
      });
      this.nodes.splice(nodeIndex, 1);
    },
    move(event) {
      const { dragged, to } = event;
      // dragged.parentId = to.id;
      if (dragged.parentId != to.id) {
        this.items.find((item) => item.id == dragged.id).parentId = to.id;
        this.$http
          .put(
            `/${this.meta.endPoint}/${dragged.id}`,
            this.items.find((item) => item.id == dragged.id)
          )
          .then((response) => {
            console.log(response);
          });
      }
    },
    onDragStart(event) {
      console.log("onDragStart", event);
      this.dragging = true;
    },
  },

  beforeDestroy() {
    this.$eventBus.$off(`refresh`);
  },
};
</script>

<style lang="scss">
.node-label {
  font-size: 12px;
  font-weight: bold;
  margin: 10px;
}

.node-type {
  background: #17638d !important;
}

.flowchart-node {
  width: 250px !important;
  height: 100px !important;
  opacity: 1 !important;
  transition: all 0.3s ease-in-out;
}

.flowchart-container path {
  stroke: #17638d !important;
}

.selected {
  box-shadow: none !important;
  transform: scale(1.2) !important;
  transition: all 0.3s ease-in-out;
}
</style>
